(function($) {

/*
*  render_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function render_map( $el ) {

// var
var $markers = $el.find('.marker');

// vars
var args = {
	zoom		: 16,
	center		: new google.maps.LatLng(0, 0),
	mapTypeId	: google.maps.MapTypeId.ROADMAP
};
	
// create map	        	
var map = new google.maps.Map( $el[0], args);

// add a markers reference
map.markers = [];
	
	
	
	
// add markers
$markers.each(function(){

	add_marker( $(this), map );

});

// center map
center_map( map );
	
	
}

// create info window outside of each - then tell that singular infowindow to swap content based on click
var infowindow = new google.maps.InfoWindow({
content		: '' 
});

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

function add_marker( $marker, map ) {

	// var
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );


	// Retina support
	var requireImagePrefix = "";
	var devicePixelRatio = (window.devicePixelRatio===undefined?1:window.devicePixelRatio);

	if(devicePixelRatio > 2) {
	  requireImagePrefix = "@3x";
	}
	else if(devicePixelRatio > 1) {
	  requireImagePrefix = "@2x";
	}

	// var - Used for creating a list of offices
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

	// Image for the marker
	// scaledSize are the dimensions of the 1x sprite
	var pinImage = {
	  url: "http://www.thefarmhouseuae.com/wp-content/themes/farmhouse/img/mapicon" + requireImagePrefix + ".png",
	  size: new google.maps.Size(24, 27),
	  scaledSize: new google.maps.Size(24, 27),
	  origin: new google.maps.Point(0, 0),
	  anchor: new google.maps.Point(7, 34)
	};

	// create marker
	var marker = new google.maps.Marker({
		position	: latlng,
		map			: map,
		//icon		: pinImage,
		optimized	: false
	});

	// add to array
	map.markers.push( marker );

	// if marker contains HTML, add it to an infoWindow
	if( $marker.html() )
	{

		// make ability to click name of office in a list
		liTag=$("body ul.maplist").find("[data-lat='" + $marker.attr('data-lat') + "']");
		$(liTag).click(function(event) {
			event.preventDefault(); // prevent # anchor from taking you to the top of the page
			//swap content of that singular infowindow
			infowindow.setContent($marker.html());
			infowindow.open(map, marker);

			// Make the link have a class of active
			$('ul.maplist').find('li a').removeClass('active');
			$(this).addClass('active');
		});

		// show info window when marker is clicked & close other markers
		google.maps.event.addListener(marker, 'click', function() {
			//swap content of that singular infowindow
			infowindow.setContent($marker.html());
			infowindow.open(map, marker);

			// Remove class active from existing li a
			$('ul.maplist').find('li a').removeClass('active');
			// Add class active to the correct li a
			$("body ul.maplist").find("[data-lat='" + $marker.attr('data-lat') + "']").addClass('active');
		});
		
		// close info window when map is clicked
		     google.maps.event.addListener(map, 'click', function(event) {
		     	// Remove any active li classes
		     	$('ul.maplist').find('li a').removeClass('active');
		     	// Close any infowindows
		        if (infowindow) {
		            infowindow.close(); }
				});

				
			
	}

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

// vars
var bounds = new google.maps.LatLngBounds();

// loop through all markers and create bounds
$.each( map.markers, function( i, marker ){

	var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

	bounds.extend( latlng );

});

// only 1 marker?
if( map.markers.length == 1 )
{
	// set center of map
    map.setCenter( bounds.getCenter() );
    map.setZoom( 16 );
}
else
{
	// fit to bounds
	map.fitBounds( bounds );
}

}

/*
*  document ready
*
*  This function will render each map when the document is ready (page has loaded)
*
*  @type	function
*  @date	8/11/2013
*  @since	5.0.0
*
*  @param	n/a
*  @return	n/a
*/

        $(document).ready(function(){
          $('.acf-map').each(function(){
            render_map( $(this) );
          });
        });

})(jQuery);